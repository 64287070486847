"use client";

import React from "react";
import { HeroParallax } from "@/components/aceternity-ui/hero-parallax";
import PhoneImage from "@/public/public_img/phone.png";
import InformationImage from "@/public/public_img/information.png";
import { TextGenerateEffect } from "@/components/aceternity-ui/text-generation";
import {
  Carousel,
  Card,
} from "@/components/aceternity-ui/apple-cards-carousel";
import { Timeline } from "@/components/aceternity-ui/timeline";

export default function HeroPage() {
  const cards = useCasesData.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <>
      <div className="container">
        <HeroParallax products={products} />
        <h2 className="container" style={{ textAlign: "center" }}>
          Notre Projet
        </h2>
        <br />
        <br />
        <div className="container">
          <TextGenerateEffect words={words} />
        </div>
      </div>
      <div className="w-full py-20 container">
        <h2 className="max-w-7xl pl-4 mx-auto text-xl md:text-5xl font-bold text-neutral-800 dark:text-neutral-200 font-sans">
          Example d'use cases
        </h2>
        <Carousel items={cards} />
        <br />
        <br />
        <br />
        <h2 className="container" style={{ textAlign: "center" }}>
          Que propose SAGESSES ?
        </h2>
        <br />
        <br />
        <h4 className="container">
          SAGESSES permet aux entreprises de gérer efficacement des demandes
          clients dépassant leurs capacités grâce à l’automatisation de la
          gestion des appels. En offrant des réponses précises basées sur des
          informations actualisées, SAGESSES optimise le temps et les ressources
          des entreprises, tout en maintenant une qualité de service élevée.
        </h4>
      </div>
      <Timeline data={timelineData}></Timeline>
    </>
  );
}

const words = `SAGESSES est une solution d’aide au traitement des appels
des services client qui utilise un grand modèle linguistique (Large Language Model : LLM) pour comprendre
et traiter les demandes des clients.`;

const products = [
  {
    title: "Répondez à vos appel manqués",
    thumbnail: PhoneImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
  {
    title: "Répondez à vos appel manqués",
    thumbnail: PhoneImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
  {
    title: "Répondez à vos appel manqués",
    thumbnail: PhoneImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
  {
    title: "Répondez à vos appel manqués",
    thumbnail: PhoneImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
  {
    title: "Répondez à vos appel manqués",
    thumbnail: PhoneImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
  {
    title: "Répondez à vos appel manqués",
    thumbnail: PhoneImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
  {
    title: "Informez vos clients",
    thumbnail: InformationImage,
  },
];

const DummyContent = () => {
  return <></>;
};

const useCasesData = [
  {
    category: "Restauration",
    title: "",
    src: "https://media.istockphoto.com/id/1141493515/photo/friendly-waitress-taking-order-on-phone-at-restaurant-and-writing-on-notepad.jpg?s=612x612&w=0&k=20&c=Z6N2Ss1n3cighbUzq8rvrfxW4NnAd1gV_PhBstxcGy8=",
    content: <DummyContent />,
  },
  {
    category: "Cabinet Médicale",
    title: "",
    src: "https://media.istockphoto.com/id/1395196867/photo/doctor-meeting-remotely-with-his-patient.jpg?s=612x612&w=0&k=20&c=SHq-_J0ND_aqZVU5EBI6ABbRMdCgI5FVXrpOlpVl_zU=",
    content: <DummyContent />,
  },
  {
    category: "Cabinet d'avocat",
    title: "",
    src: "https://media.istockphoto.com/id/1289795796/photo/themis-figurine-on-table-with-blurred-lawyer-talking-on-mobile-phone-on-background.jpg?s=612x612&w=0&k=20&c=KQSV8bgKRwyaFCXJNLCVSenUMEHgjDdj6voEUlfB1k4=",
    content: <DummyContent />,
  },
  {
    category: "Artisan",
    title: "",
    src: "https://www.artisandubatiment.fr/wp-content/uploads/2019/07/Creer-un-site-web-quand-on-est-artisan.jpg",
    content: <DummyContent />,
  },
];

const timelineData = [
  {
    title: "Début Q4 2024",
    content: (
      <div>
        <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-8">
          Commencement de la bêta test.
        </p>
      </div>
    ),
  },
  {
    title: "FIN Q3 2024",
    content: (
      <div>
        <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-8">
          Amelioration de la latence de l'IA.
        </p>
      </div>
    ),
  },
  {
    title: "Milieu Q3 2024",
    content: (
      <div>
        <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-8">
          Gestion de plusieurs appel en simultanée.
        </p>
        <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-8">
          Amélioration intéraction avec assistant + Application mobile créée
        </p>
      </div>
    ),
  },
  {
    title: "Début Q3 2024",
    content: (
      <div>
        <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-8">
          Projet Completement réécrit.
        </p>
      </div>
    ),
  },
  {
    title: "Q2 2024",
    content: (
      <div>
        <p className="text-neutral-800 dark:text-neutral-200 text-xs md:text-sm font-normal mb-4">
          Premier POC
        </p>
      </div>
    ),
  },
];
