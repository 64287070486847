import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Combines multiple class names into a single string.
 *
 * @param inputs - The class names to combine.
 * @returns The combined class names as a string.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Converts time in hours, minutes, and seconds to milliseconds.
 *
 * @param {number} [seconds=0] - The number of seconds.
 * @param {number} [minutes=0] - The number of minutes.
 * @param {number} [hours=0] - The number of hours.
 * @returns {number} The total time in milliseconds.
 */
export default function convertToMilliseconds(
  seconds: number = 0,
  minutes: number = 0,
  hours: number = 0
): number {
  const hoursInMilliseconds = hours * 3600000;
  const minutesInMilliseconds = minutes * 60000;
  const secondsInMilliseconds = seconds * 1000;

  return hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds;
}
